import { toast } from 'react-toastify';
import { sendReport } from '../../../libs/api/quizStatApi';
import { useFormik } from 'formik';
import * as Yup from 'yup';

interface Props {
  onSuccessCallback: (params: any) => void;
}

const useSendReportForm = ({ onSuccessCallback }: Props) => {
  const validationSchema: any = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    email: Yup.string()
      .required('Email is required.')
      .email('Email format is invalid.'),

    terms: Yup.boolean().isTrue('Consent required to proceed.'),
    email_consent: Yup.boolean().isTrue('You have to agree to receive emails.'),
  });

  const handleError = (
    error: any,
    values: any,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    switch (error.message) {
      case 'EMAIL_INVALID_OR_TEMPORARY':
        onSuccessCallback(values);
        return;
      case 'INVALID_EMAIL_FORMAT':
        toast.error('The email field must be a valid email address.');
        setSubmitting(false);
        return;
      default:
        toast.error(error.message);
        setSubmitting(false);
    }
  };

  const onSubmit = (values: any, { setSubmitting }: any) => {
    sendReport(values)
      .then(() => onSuccessCallback(values))
      .catch((error: any) => handleError(error, values, setSubmitting));
  };

  const form = useFormik({
    initialValues: {
      name: '',
      email: '',
      terms: false,
      email_consent: false,
    },
    validationSchema,
    onSubmit,
    validateOnBlur: false,
  });

  return {
    form,
  };
};

export default useSendReportForm;
